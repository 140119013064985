/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Tabs, Tab, CircularProgress } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { onSnapshot, doc } from 'firebase/firestore';
import { db } from '../../App';
import Communication from './Communication';
import { User } from '../../types';
import LoginPage from '../Members/LoginPage';
import SubmittedForms from './SubmittedForms';
//import Members from './Members';
import ContactComponent from './Contacts';
import Announcements from './Announcements';
import Events from './Events';
import { sundaySchoolTeachers } from '../../utils';
import Bulletins from './Bulletins';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const pages = [
  'submitted-forms',
  'members',
  'announcements',
  'events',
  'bulletins',
];

const Admin = () => {
  const { page } = useParams();
  const auth = getAuth();
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState<User>();
  const nav = useNavigate();
  const [busy, setBusy] = useState(false);

  const value = page ? pages.indexOf(page) + 1 : 0;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);
    nav(`/admin/${newValue === 0 ? '' : pages[newValue - 1]}`);
  };

  useEffect(() => {
    if (user?.uid) {
      setBusy(true);
      const unsub = onSnapshot(doc(db, 'users', user.uid), (doc) => {
        if (doc.exists()) {
          setUserData(doc.data() as User);
          setBusy(false);
        }
      });
      return () => unsub();
    }
  }, [user]);

  if (busy) return <CircularProgress />;
  if (!userData?.isAdmin) return <LoginPage onUserSet={() => {}} />;
  if (sundaySchoolTeachers.includes(userData.email.toLowerCase())) {
    return <SubmittedForms user={userData} />;
  }
  return (
    <Box component={'span'}>
      <div className="contactus-hero">Admin</div>
      <Box sx={{ width: '100%' }}>
        <Box className="no-print">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
            indicatorColor="secondary"
            scrollButtons="auto"
          >
            <Tab label="Communication" {...a11yProps(0)} />
            <Tab label="Submitted Forms" {...a11yProps(1)} />
            <Tab label="Members" {...a11yProps(2)} />
            <Tab label="Announcements" {...a11yProps(3)} />
            <Tab label="Events" {...a11yProps(4)} />
            <Tab label="Bulletins" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Communication user={userData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SubmittedForms user={userData} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ContactComponent />
          {/* <Members /> */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Announcements user={userData} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Events user={userData} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Bulletins user={userData} />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Admin;
