import { Box } from '@mui/material';
import React from 'react';

const Calendar: React.FC = () => {
  return (
    <Box sx={{ padding: 2, backgroundColor: '#fff3cc' }}>
      <h1 style={{ textAlign: 'center' }}>NMZ Calendar</h1>

      <div
        style={
          {
            //   position: 'relative',
            //   overflow: 'hidden',
            //   paddingTop: '96.25%',
          }
        }
      >
        {/* <iframe
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FNew_York&showPrint=0&src=bWVkaWFAbm16bGFrZWxhbmQub3Jn&src=Y184MTIwZGZkOGMxMzEyZTJhN2VhNmE3Zjk2MGIxZDQ2NTNmMzllNjAzYTYwMWRiMWIwNjkwM2NlZmM2YmM4NGFiQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%238E24AA&color=%230B8043"
          style="border:solid 1px #777"
          width="800"
          height="600"
          frameborder="0"
          scrolling="no"
        ></iframe> */}

        <iframe
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FNew_York&showPrint=0&mode=AGENDA&showTz=0&src=Y184MTIwZGZkOGMxMzEyZTJhN2VhNmE3Zjk2MGIxZDQ2NTNmMzllNjAzYTYwMWRiMWIwNjkwM2NlZmM2YmM4NGFiQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%238E24AA"
          style={{
            border: 0,
            //position: 'absolute',
            // top: 0,
            // left: 0,
            width: '100%',
            height: '100%',
            minHeight: '480px',
          }}
          frameBorder="0"
          scrolling="no"
          title="Google Calendar"
        ></iframe>
      </div>
    </Box>
  );
};

export default Calendar;
