import React from 'react';
import Button from '@mui/material/Button';
import { Box, Card, CardContent, CardMedia } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEvents } from '../../services';
import { colors } from '../../utils';
import { format } from 'date-fns';
import { CalendarMonth } from '@mui/icons-material';

const WhatsHappening = () => {
  const { events: allEvents } = useEvents();
  const isMobile = window.matchMedia(
    'only screen and (max-width: 760px)'
  ).matches;

  const events = allEvents.filter(
    (e) => e.endTime && e.endTime.getTime() > new Date().getTime()
  );

  const settings = {
    dots: false,
    //infinite: true,
    //speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    //slidesToScroll: 1,
    //arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const boxes = () =>
    events.map((m) => (
      <div key={m.id}>
        <Card
          key={m.id}
          sx={{
            //margin: 1,
            height: 'auto',
            width: '95%',
          }}
        >
          {/* <Link to={`/ministries/${m.id}`}> */}
          <CardMedia
            component="img"
            className="clickable-card-image"
            height={'50%'}
            //height={isMobile ? '200' : '50'}
            sx={{
              width: { md: '100%', xs: '100%' },
            }}
            //width={{ md: '50%', xs: '100%' }}
            //width="100vw"
            image={
              m.media.length > 0
                ? m.media[0]
                : require(`../../images/nmz_prev.jpg`)
            }
            alt={m.title}
          />
          {/* </Link> */}
          <CardContent>
            <Box
              sx={{
                fontWeight: 'bold',
                //margin: 1,
                fontSize: { md: '1.3rem', xs: '1rem' },
                height: '50px',
                WebkitLineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {m.title}
            </Box>
            <div
              style={{
                color: '#333',
                textAlign: 'center',
                marginBottom: 15,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CalendarMonth />
              <div>
                {format(m.startTime, 'Pp')} - {format(m.endTime, 'Pp')}
              </div>
            </div>
            <div
              style={{
                color: '#888',
                textAlign: 'center',
                marginBottom: 15,
                height: '40px',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {m.description}
            </div>
            <Button
              variant="outlined"
              color="primary"
              href={`/events/${m.id}`}
              style={{
                borderColor: colors.themeRed,
                color: colors.themeRed,
              }}
            >
              Learn More
            </Button>
          </CardContent>
        </Card>
      </div>
    ));
  return (
    <div className="ministries" style={{ background: '#eee' }}>
      <div
        className="ministry-header"
        style={{ marginTop: '2rem' }}
      >{`Upcoming Events`}</div>

      <div
        style={{
          width: isMobile ? '75vw' : '85vw',
          // backgroundColor: colors.themeRed,
          padding: '20px',
        }}
      >
        <Slider {...settings}>{boxes()}</Slider>
      </div>
      <Button
        variant="contained"
        href="/events"
        color="primary"
        style={{ backgroundColor: colors.themeRed, margin: '20px' }}
      >
        View Upcoming Events
      </Button>
    </div>
  );
};

export default WhatsHappening;
