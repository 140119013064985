import React, { useEffect } from 'react';

import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import About from './components/About/About';
import ContactUs from './components/ContactUs/ContactUs';
import Donate from './components/Donate/Donate';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import Ministries from './components/Ministries';
import MinistryDetails from './components/MinistryDetails';
import Services from './components/Services';
import Events from './components/Events/Events';
import Members from './components/Members/Members';
import Forms from './components/Forms/Forms';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

import { getFirestore } from 'firebase/firestore';
import FormDetails from './components/Forms/FormDetails';
import CompletedForm from './components/Forms/CompletedForm';
import Admin from './components/Admin/Admin';
import Media from './components/Admin/Media';
import BackgroundLetter from './components/Forms/BackgroundLetter';
import BudgetPlanningLetter from './components/Forms/BudgetPlanningLetter';
import SundayAnnouncements from './components/SundayAnnouncements';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export const firebaseConfig = {
  apiKey: 'AIzaSyBaFu046hRNS-tGm-e0dsh3OvAQRBoDtWs',
  authDomain: 'nmzlakeland-a8568.firebaseapp.com',
  projectId: 'nmzlakeland-a8568',
  storageBucket: 'nmzlakeland-a8568.appspot.com',
  messagingSenderId: '187907537985',
  appId: '1:187907537985:web:4d0c0c80db074e216ddac0',
  measurementId: 'G-5FHNG08N1M',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Marriage = () => {
  useEffect(() => {
    window.location.href = 'https://forms.gle/du7CtAEdqhsZGyqz6';
  }, []);
  return null;
};

const App: React.FC = () => {
  const router = createBrowserRouter([
    {
      element: (
        <>
          <Header />
          <ScrollToTop />
          <Outlet />
        </>
      ),
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/services',
          element: <Services />,
        },
        {
          path: '/ministries',
          element: <Ministries />,
        },
        {
          path: '/ministries/:id',
          element: <MinistryDetails />,
        },
        {
          path: 'about/:page?',
          element: <About />,
        },
        {
          path: 'contact-us',
          element: <ContactUs />,
        },
        {
          path: 'donate',
          element: <Donate />,
        },
        {
          path: 'give',
          element: <Donate />,
        },
        {
          path: 'events/:id?',
          element: <Events />,
        },
        {
          path: 'members/:page?',
          element: <Members />,
        },
        {
          path: 'forms',
          element: <Forms />,
        },
        {
          path: 'forms/:id?',
          element: <FormDetails />,
        },
        {
          path: 'forms/:id/completed',
          element: <CompletedForm />,
        },
        {
          path: 'admin/:page?',
          element: <Admin />,
        },
        {
          path: 'volunteer-application-procedure',
          element: <BackgroundLetter />,
        },
        {
          path: 'background-checks',
          element: <BackgroundLetter />,
        },
        {
          path: 'budget-planning-letter-2025',
          element: <BudgetPlanningLetter />,
        },
        {
          path: 'sunday-announcements',
          element: <SundayAnnouncements />,
        },
        {
          path: 'marriage',
          element: <Marriage />,
        },
      ],
    },
    {
      children: [
        {
          path: 'm/:media',
          element: <Media />,
        },
        {
          path: '/ministries/marriage',
          element: <Marriage />,
        },
        {
          path: 'marriage',
          element: <Marriage />,
        },
      ],
    },
  ]);

  // const router = createBrowserRouter([
  //   {
  //     path: '/',
  //     element: <Home />,
  //   },
  //   {
  //     path: '/services',
  //     element: <Services />,
  //   },
  //   {
  //     path: '/ministries',
  //     element: <Ministries />,
  //   },
  //   {
  //     path: '/ministries/:id',
  //     element: <MinistryDetails />,
  //   },
  //   {
  //     path: 'about',
  //     element: <About />,
  //   },
  //   {
  //     path: 'contact-us',
  //     element: <ContactUs />,
  //   },
  //   {
  //     path: 'donate',
  //     element: <Donate />,
  //   },
  // ]);
  const isMedia = window.location.pathname.startsWith('/m/');
  return (
    <div style={{ flex: 1 }}>
      <div style={{ flex: 1 }}>
        {/* <Header /> */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </div>
      {!isMedia && <Footer />}
    </div>
  );
};

export default App;
