/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Container,
  Alert,
  Snackbar,
  List,
  ListSubheader,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { ChurchEvent, useEvents } from '../../services';
import { User } from '../../types';
import { createSquareImage } from '../../utils/cropImage';
import { DateTimePicker } from '@mui/x-date-pickers';

/**
 * 
 * @param param0 export interface ChurchEvent {
  id: string;
  title: string;
  description: string;
  startTime: Date;
  endTime?: Date | null;
  media: string[];
  creator: string;
  signUpFormId?: string;
  link: string;
  externalLink?: string;
}

 * @returns 
 */
const Events = ({ user }: { user: User }) => {
  const [newEvent, setNewEvent] = useState<ChurchEvent>({
    title: '',
    startTime: new Date(),
    endTime: new Date(),
    media: [],
    creator: user?.userId,
    id: '',
    description: '',
    link: '',
  });

  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const { events, createEvent, updateEvent, deleteEvent } = useEvents();
  const [editingEvent, setEditingEvent] = useState<ChurchEvent | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const validFiles = Array.from(files).filter((file) =>
        ['image/jpeg', 'image/png'].includes(file.type)
      );
      if (validFiles.length + newEvent.media.length > 5) {
        alert('You can only upload up to 5 files.');
        return;
      }

      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target?.result as string;
          img.onload = () => {
            const canvas = createSquareImage(img);
            const dataUrl = canvas.toDataURL(file.type);
            setNewEvent((prevEvent) => ({
              ...prevEvent,
              media: [...prevEvent.media, dataUrl],
            }));
          };
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleRemoveMedia = (index: number) => {
    const updatedMedia = newEvent.media.filter((_, i) => i !== index);
    setNewEvent({ ...newEvent, media: updatedMedia });
  };

  const handleSubmit = async () => {
    setSaving(true);
    if (editingEvent) {
      await updateEvent(editingEvent.id, newEvent);
    } else {
      await createEvent(newEvent);
    }
    setSaving(false);
    setOpen(true);
    setEditingEvent(null);
    const tomorrowAtNineAM = new Date();
    tomorrowAtNineAM.setDate(tomorrowAtNineAM.getDate() + 1);
    tomorrowAtNineAM.setHours(4, 0, 0, 0);

    const tomorrowAt10AM = new Date();
    tomorrowAt10AM.setDate(tomorrowAt10AM.getDate() + 1);
    tomorrowAt10AM.setHours(5, 0, 0, 0);

    setNewEvent({
      title: '',
      startTime: tomorrowAtNineAM,
      endTime: tomorrowAt10AM,
      media: [],
      creator: user?.userId,
      id: '',
      description: '',
      link: '',
    });
  };

  const handleEdit = (event: ChurchEvent) => {
    setEditingEvent(event);
    setNewEvent(event);
  };

  const handleDeleteClick = (id: string) => {
    setEventToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (eventToDelete) {
      await deleteEvent(eventToDelete);
      setDeleteDialogOpen(false);
      setEventToDelete(null);
    }
  };

  useEffect(() => {
    const tomorrowAtNineAM = new Date();
    tomorrowAtNineAM.setDate(tomorrowAtNineAM.getDate() + 1);
    tomorrowAtNineAM.setHours(4, 0, 0, 0);

    const tomorrowAt10AM = new Date();
    tomorrowAt10AM.setDate(tomorrowAt10AM.getDate() + 1);
    tomorrowAt10AM.setHours(5, 0, 0, 0);

    setNewEvent((p) => ({
      ...p,
      startTime: tomorrowAtNineAM,
      endTime: tomorrowAt10AM,
    }));
  }, []);
  if (!user?.isAdmin) return null;

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <h2>Create a new Event</h2>
        <TextField
          label="Title"
          value={newEvent.title}
          onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
          style={{ marginBottom: '1rem', minWidth: '400px' }}
        />
        <TextField
          label="Description"
          value={newEvent.description}
          onChange={(e) =>
            setNewEvent({ ...newEvent, description: e.target.value })
          }
          style={{ marginBottom: '1rem', minWidth: '400px' }}
          multiline
        />
        <TextField
          label="Link (Optional)"
          value={newEvent.link}
          onChange={(e) => setNewEvent({ ...newEvent, link: e.target.value })}
          style={{ marginBottom: '1rem', minWidth: '400px' }}
        />
        <DateTimePicker
          label="Start Date and Time"
          value={newEvent.startTime}
          onChange={(date) =>
            date && setNewEvent({ ...newEvent, startTime: date })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ marginBottom: '1rem', minWidth: '400px' }}
            />
          )}
        />

        <DateTimePicker
          label="End Date and Time"
          value={newEvent.endTime}
          onChange={(date) =>
            date && setNewEvent({ ...newEvent, endTime: date })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ marginBottom: '1rem', minWidth: '400px' }}
            />
          )}
        />

        {/* <TextField
          label="Start Date and Time"
          type="datetime-local"
          // step={60}
          value={newEvent.startTime.toISOString().slice(0, 16)}
          onChange={(e) =>
            setNewEvent({ ...newEvent, startTime: new Date(e.target.value) })
          }
          style={{ marginBottom: '1rem', minWidth: '400px' }}
        />
        <TextField
          label="End Date and Time"
          type="datetime-local"
          value={newEvent.endTime?.toISOString().slice(0, 16) || ''}
          onChange={(e) =>
            setNewEvent({ ...newEvent, endTime: new Date(e.target.value) })
          }
          style={{ marginBottom: '1rem', minWidth: '400px' }}
        /> */}
        <input
          type="file"
          accept="image/jpeg, image/png"
          multiple
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => fileInputRef.current?.click()}
          style={{ marginBottom: '1rem' }}
        >
          Attach Flyers
        </Button>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {newEvent.media.map((src, index) => (
            <Box key={index} sx={{ position: 'relative', margin: '5px' }}>
              <img
                src={src}
                alt={`flyer-${index}`}
                style={{ width: '100px' }}
              />
              <IconButton
                size="small"
                sx={{ position: 'absolute', top: 0, right: 0 }}
                onClick={() => handleRemoveMedia(index)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={saving ? <CircularProgress size={20} /> : null}
          disabled={saving || !newEvent.title || !newEvent.description}
          onClick={handleSubmit}
        >
          {saving ? 'Saving...' : 'Save'}
        </Button>
        <List
          subheader={<ListSubheader sx={{ mt: 5 }}>Events</ListSubheader>}
          sx={{ width: '80%' }}
        >
          {events.map((event) => (
            <React.Fragment key={event.id}>
              <ListItem>
                <ListItemText
                  primary={event.title}
                  secondary={`${event.description} - ${new Date(
                    event.startTime
                  ).toLocaleDateString()}`}
                />
                {event.media &&
                  event.media.map((m) => (
                    <img
                      src={m}
                      alt={event.title}
                      key={m}
                      style={{ width: '100px' }}
                    />
                  ))}
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteClick(event.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this event?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity="success"
            sx={{ width: '100%' }}
          >
            Event {editingEvent ? 'Updated' : 'Added'} Successfully
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default Events;
